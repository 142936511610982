<template>

  <div>
    
    <grid
      ref="grid"
      :service="service" 
      :route_grid_path="`${id}/logs`"
      :column_formats="{
        'status': (val) => val || '',
        'participant': (val) => val || '<b class=\'text-warning\'>Não Reconhecido</b>',
        'created_at': (val) => $utils.format.date(val)
      }"
    >
      <template v-slot:filterZone>
        <div class="flex items-center">
          <vs-checkbox v-model="participantless" color="warning"/>
          <vs-label :class="['text-sm select-none font-bold', {'text-warning': participantless}]">{{ $t('participante-nao-reconhecido') }}</vs-label>
        </div>
      </template>
      <template v-slot:actions="actions">
        <feather-icon
          icon="EyeIcon"
          svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
          v-tooltip.auto="{
            content: 'Abrir detalhamento',
            delay: {
              show: 500,
              hide: 500
            }
          }"
          @click="() => openLogs(actions.row)"
        />
      </template>
    </grid>

    <physical-questionnaire-log-viewer :service="service"/>
  </div>
  
</template>

<script>
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'
import PhysicalQuestionnaireLogViewer from './PhysicalQuestionnaireLogViewer.vue'
export default {
  components: { PhysicalQuestionnaireLogViewer },
  props: {
    id: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    service: null,
    participantless: false,
  }),

  watch: {
    participantless(val) {
      this.$refs.grid.flashFilters([], {
        participantless: val
      })
    }
  },

  methods: {
    openLogs(participant) {
      this.$emit('open-logs', participant)
    },
  },

  beforeMount() {
    this.service = PhysicalQuestionnaireService.build(this.$vs)
  }

}
</script>

<style>
</style>