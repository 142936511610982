var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    {
      staticClass: "_physical-questionnaire-form",
      attrs: {
        title:
          _vm.id === null
            ? _vm.$t("physical_questionnaires_title")
            : _vm.$t("physical_questionnaires_edit"),
      },
    },
    [
      _c(
        "vs-tabs",
        {
          model: {
            value: _vm.activeTab,
            callback: function ($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab",
          },
        },
        [
          _c(
            "vs-tab",
            { attrs: { label: _vm.$t("configuracao") } },
            [_c("physical-questionnaire-config", { attrs: { id: _vm.id } })],
            1
          ),
          _vm.showParticipantsTab
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("participantes") } },
                [
                  _c("physical-questionnaire-participants", {
                    attrs: { id: _vm.id },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showLogsTab
            ? _c(
                "vs-tab",
                { attrs: { label: _vm.$t("envios") } },
                [_c("physical-questionnaire-logs", { attrs: { id: _vm.id } })],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }