<template>
  <div class="_physical-answer-correction grid grid-cols-2">
    <div class="__answer-card-image">
      <img class="object-contain" :src="sheetFileUrl"/>
    </div>
    <div class="__answer-correction-panel">
      <correction-user-page
        :key="key"
        :model="userAnswer"
        :fields="questions"
        :show-comment-button="false"
        @showCompleteQuestionModal="showCompleteQuestionModal"
      />
    </div>
    <vs-popup class="__complete-question-popup" title :active.sync="showCompleteQuestion" :fullscreen="true">
      <correction-complete-question
        :complete-question="completeQuestion"
        :questionnaire-answer-service="questionnaireAnswerService"
      />
    </vs-popup>
  </div>
</template>

<script>
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'
import QuestionnaireAnswerService from '@/services/api/QuestionnaireAnswerService'
import CorrectionUserPage from '../questionnaires/elements/questionnaire_answers/CorrectionUserPage.vue'
import CorrectionCompleteQuestion from '@/views/apps/questionnaires/elements/questionnaire_answers/CorrectionCompleteQuestion.vue'

export default {
  components: { CorrectionUserPage, CorrectionCompleteQuestion },

  props: {
    participantId: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    physicalService: null,
    questionnaireAnswerService: null,
    userAnswer: null,
    showCompleteQuestion: false,
    completeQuestion: null,
    key: 0
  }),

  computed: {
    questions() {
      return _.get(this.userAnswer, 'questions', []) || []
    },
    showAnswers() {
      return this.questions.length > 0
    },
    sheetFileUrl() {
      return _.get(this.userAnswer, 'answer_card_url')
    },
  },

  beforeDestroy() {
    this.$root.$off('corrected', this.updateData)
  },

  beforeMount() {
    this.physicalService = PhysicalQuestionnaireService.build(this.$vs)
    this.questionnaireAnswerService = QuestionnaireAnswerService.build(this.$vs)
  },

  mounted() {
    this.$vs.loading()
    this.$root.$on('corrected', this.updateData)
    this.physicalService.getParticipantCorrection(this.participantId).then(
      response => {
        this.userAnswer = _.has(response, 'user_name') ? response : null
        this.$vs.loading.close()
      }
    )
  },

  methods: {
    updateData(question, update) {
      this.showCompleteQuestion = false
      this.physicalService.getParticipantCorrection(this.participantId).then(
        response => {
          this.userAnswer = _.has(response, 'user_name') ? response : null
          this.key++
        }
      )
    },
    showCompleteQuestionModal(dataAnswer) {
      this.key++
      const question = this.questions.find(element => {
        return element.id === dataAnswer.question_id
      })
      if (question) {
        this.completeQuestion = {
          ...question,
          ...dataAnswer,
          score: this.formatFloat(question.data.score),
          answer_score: dataAnswer.score
        }
        this.showCompleteQuestion = true
      }
    },
  }

}
</script>

<style lang="scss">

.__answer-card-image > img {
  max-width: 45vw;
}

.__answer-correction-panel {
  max-height: 83vh;
  @apply overflow-y-scroll;

  & .vx-card__header {
    margin: 0;
    padding: 0;
    display: none;
  }

  & .vx-card__body {
    padding: 0 !important;
  }
}

.__complete-question-popup {
  z-index: 55000;
}

</style>