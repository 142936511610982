<template>
  <div>
    <vs-popup
      :title="$t('envios-_-get-participant-data-name', [_.get(participant, `data.name`)])"
      :active.sync="showLogs"
      style="z-index: 54000;"
    >
      <div class="flex flex-col gap-4" v-if="participant">
        <div class="h-32 flex flex-col justify-center align-center" v-if="_.get(participant, 'log_count', 0) === 0">
          <span class="p-2 font-bold text-center text-warning">
            {{ $t('o-cartao-resposta-deste-participante-nao-foi-enviado') }}
          </span>
        </div>
        <vs-table v-if="logs" :data="logs">
          <template slot="thead">
            <vs-th>
              ID
            </vs-th>
            <vs-th>
              {{ $t('criado-em') }}
            </vs-th>
            <vs-th>
              {{ $t('mensagens') }}
            </vs-th>
            <vs-th>
              {{ $t('Actions') }}
            </vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="data[indextr].id">
                {{data[indextr].id}}
              </vs-td>

              <vs-td :data="data[indextr].created_at">
                {{ $utils.format.dateWithFormat(data[indextr].created_at, 'DD/MM/YYYY - kk:mm:ss') }}
              </vs-td>

              <vs-td>
                {{ _.get(data[indextr], 'data.messages', []).length }}
              </vs-td>

              <vs-td>
                <feather-icon
                  icon="EyeIcon"
                  class="cursor-pointer"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                  @click="() => openMessages(indextr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t('mensagens-0')"
      :active.sync="showMessages"
      style="z-index: 54001;"
    >
      <div class="flex flex-col gap-4" v-if="log">
        <log-messages v-if="log" :messages="logMessages"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import LogMessages from '@/components/LogMessages.vue'
export default {
  components: { LogMessages },

  props: {
    service: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    showLogs: false,
    showMessages: false,
    participant: null,
    logs: null,
    logIndex: null,
  }),

  watch: {
    showLogs(value) {
      if (!value) this.closeLogs()
    },
    showMessages(value) {
      if (!value) this.closeMessages()
    }
  },

  mounted() {
    this.$parent.$on('open-logs', this.loadParticipantLogs)
  },

  beforeDestroy() {
    this.$parent.$off('open-logs', this.loadParticipantLogs)
  },

  methods: {
    openMessages(index) {
      this.logIndex = index
      this.showMessages = true
    },
    closeLogs() {
      this.logs = null
      this.participant = null
      this.$emit('closed-logs')
    },
    closeMessages() {
      this.logIndex = null
      this.$emit('closed-messages')
    },
    loadParticipantLogs(participant) {
      const id = _.get(participant, 'id')
      this.participant = participant

      if (id) {
        this.$vs.loading()
        this.service.getParticipantLogs(id).then(
          response => {
            this.logs = _.isArray(response) ? response : null
            this.$emit('logs-loaded', response)
            this.$vs.loading.close()
            this.showLogs = true
          },
          error => {
            this.notifyError(this.$vs, this.$t('nao-foi-possivel-carregar-os-envios-do-cartao-deste-participante'))
            this.$vs.loading.close()
          }
        )
      }
    }
  },

  computed: {
    logMessages() {
      if (this.log) {
        return _.get(this.log, 'data.messages', [])
      } else {
        return []
      }
    },
    log() {
      if (this.logIndex !== null) {
        return _.get(this.logs, this.logIndex)
      } else {
        return null
      }
    },
  },


}
</script>

<style>

</style>