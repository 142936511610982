<template>
  <div>
    <div class="flex flex-wrap items-center">
      <vue-dropzone
        id="upload"
        ref="fileUploadZone"
        v-on:vdropzone-success="success"
        v-on:vdropzone-sending="sending"
        v-on:vdropzone-error="error"
        v-on:vdropzone-file-added="added"
        v-on:vdropzone-queue-complete="complete"
        :use-font-awesome="true"
        :options="dropzoneOptions"
        :use-custom-dropzone-options="true"
        :max_number_of_files="1"
        :useCustomSlot="true"
        style="width: 100%; margin-bottom: 10px">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">
            {{ $t('arraste-e-solte-para-enviar-o-arquivo') }}
          </h3>
          <div class="subtitle">
            {{ $t('ou-selecione-um-arquivo-do-seu-computador') }}
          </div>
        </div>
      </vue-dropzone>
    </div>
    <div class="flex gap-2 col-span-12 justify-end my-2" v-if="showButtonProcessUpload">
        <vs-button
          @click="sendFile"
          class="p-2 w-full"
          color="primary"
          icon-pack="feather"
          icon="icon-file-plus">
          {{$t('action.import')}}
        </vs-button>
      </div>
  </div>
</template>


<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import standard from '@/services/standard'

export default {
  components: {
    vueDropzone: vue2Dropzone,
  },
  props: {
    uploadUrl: {
      type: String,
      default: `${process.env.VUE_APP_API_BASE_URL}/api/v1/medias/undefined/optimize_image`
    },
    image: {
      type: String,
      defaul: ''
    },
    maxWidth: {
      type: String,
      default: '100px'
    },
    maxHeight: {
      type: String,
      default: '100px'
    },
    accept: {
      type: String,
      default: null
    },
    maxFiles:  {
      type: Number,
      default: null
    },
    autoProcessUpload:{
      type: Boolean,
      default: true
    },
    customMessageSuccess:{
      type: String,
      default: null
    }
  },
  model: {
    prop: 'image',
    event: 'input'
  },
  data() {
    return {
      dropzoneOptions: {
        headers: { Authorization: `Bearer ${standard.token()}` },
        url: this.uploadUrl,
        thumbnailWidth: 150,
        addRemoveLinks: true,
        autoProcessQueue: this.autoProcessUpload,
        timeout: 180000,
        maxFiles: this.maxFiles,
        acceptedFiles: this.accept,
        init :  function() { //bug fix, when xlsx or docsx upload mac
          this.on('addedfile',  function(file) {
            if (!file.type && this.accept !== null) {
              //Excel
              if (this.accept.indexOf('excel') > 0) {
                const ext = file.name.split('.').pop()
                if (ext === 'xls') {
                  Object.defineProperty(file, 'type', { value: 'application/vnd.ms-excel' }) // MIME type
                }
              }
            }
          })
        }//End init function
      },
      showUploadPopup: false
    }
  },
  computed: {
    showButtonProcessUpload() {
      return !this.autoProcessQueue
    }
  },
  methods: {
    showImageUploadModal() {
      this.showUploadPopup = true
    },
    success(file, data) {
      // this.$refs.fileUploadZone.processQueue()
      // const response = JSON.parse(file.xhr.response)
      //console.log(file.dataURL)
      this.$emit('input', data)
      this.setImage(file)
      this.showUploadPopup = false
      this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
      if (this.customMessageSuccess) {
        this.notifySuccess(this.$vs, this.$t(this.customMessageSuccess), 5000)
      }
    },
    setImage(file) {
      if (file.dataURL) {
        this.image = file.dataURL
      }
      //this.$emit('input', data)
    },
    sending(file, xhr, formData) {
      // formData.append('something', this.selectedOptionId)
    },
    error(file) {
      if (file.status === 'error') {
        this.notifyError(this.$vs, file.xhr.response)
        this.removeAllFiles()
      }
    },
    added(file) {
      file['key'] = this.getFilenameIndex(file.name)
    },
    complete(file, xhr, formData) {
      this.removeAllFiles()
    },
    removeAllFiles() {
      this.$refs.fileUploadZone.removeAllFiles()
    },
    getFilenameIndex(name) {
      const res = name.replace(' ', '_')
      return res.replace(/[^0-9a-zA-Z]/g, '')
    },
    sendFile() {
      const lengthFile = this.$refs.fileUploadZone.getQueuedFiles().length
      if (lengthFile > 0) {
        this.$refs.fileUploadZone.processQueue()
      } else {
        this.notifyWarning(this.$vs, this.$t('nenhum-arquivo-selecionado'), 5000)
      }
    }
  }
}
</script>
