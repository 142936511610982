<template>
  <vs-table :data="messages" :sst="true" current-sort-type="asc">
    <template slot="thead">
      <vs-th sort-key="text">{{ $t('mensagem') }}</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :class="`_log-message-viewer_${i}`" :key="data[i]" v-for="(tr, i) in data">
        <vs-td class="break-all p-1 pl-4">
          <div class="w-full">
            <div v-html-safe="formatValue(_.get(tr, 'text'))"/>
          </div>
          <div class="w-full text-sm">
            <label> {{ $utils.format.dateWithFormat(_.get(tr, 'time'), 'DD/MM/YYYY - kk:mm:ss') }} </label>
          </div>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({

  }),

  methods: {
    formatValue(value) {
      if (typeof value === 'string' || value instanceof String) {
        return value
      }
      return JSON.stringify(value)
    }
  }
}
</script>

<style>

</style>