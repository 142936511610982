var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex flex-wrap items-center" },
      [
        _c(
          "vue-dropzone",
          {
            ref: "fileUploadZone",
            staticStyle: { width: "100%", "margin-bottom": "10px" },
            attrs: {
              id: "upload",
              "use-font-awesome": true,
              options: _vm.dropzoneOptions,
              "use-custom-dropzone-options": true,
              max_number_of_files: 1,
              useCustomSlot: true,
            },
            on: {
              "vdropzone-success": _vm.success,
              "vdropzone-sending": _vm.sending,
              "vdropzone-error": _vm.error,
              "vdropzone-file-added": _vm.added,
              "vdropzone-queue-complete": _vm.complete,
            },
          },
          [
            _c("div", { staticClass: "dropzone-custom-content" }, [
              _c("h3", { staticClass: "dropzone-custom-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("arraste-e-solte-para-enviar-o-arquivo")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("ou-selecione-um-arquivo-do-seu-computador")
                    ) +
                    " "
                ),
              ]),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm.showButtonProcessUpload
      ? _c(
          "div",
          { staticClass: "flex gap-2 col-span-12 justify-end my-2" },
          [
            _c(
              "vs-button",
              {
                staticClass: "p-2 w-full",
                attrs: {
                  color: "primary",
                  "icon-pack": "feather",
                  icon: "icon-file-plus",
                },
                on: { click: _vm.sendFile },
              },
              [_vm._v(" " + _vm._s(_vm.$t("action.import")) + " ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }