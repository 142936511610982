var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "_physical-answer-correction grid grid-cols-2" },
    [
      _c("div", { staticClass: "__answer-card-image" }, [
        _c("img", {
          staticClass: "object-contain",
          attrs: { src: _vm.sheetFileUrl },
        }),
      ]),
      _c(
        "div",
        { staticClass: "__answer-correction-panel" },
        [
          _c("correction-user-page", {
            key: _vm.key,
            attrs: {
              model: _vm.userAnswer,
              fields: _vm.questions,
              "show-comment-button": false,
            },
            on: { showCompleteQuestionModal: _vm.showCompleteQuestionModal },
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "__complete-question-popup",
          attrs: {
            title: "",
            active: _vm.showCompleteQuestion,
            fullscreen: true,
          },
          on: {
            "update:active": function ($event) {
              _vm.showCompleteQuestion = $event
            },
          },
        },
        [
          _c("correction-complete-question", {
            attrs: {
              "complete-question": _vm.completeQuestion,
              "questionnaire-answer-service": _vm.questionnaireAnswerService,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }