<template>
  <vx-card
    class="_physical-questionnaire-form"
    :title="id === null ? $t('physical_questionnaires_title') : $t('physical_questionnaires_edit')"
  >
    <vs-tabs v-model="activeTab">
      <vs-tab :label="$t('configuracao')">
        <physical-questionnaire-config :id="id"></physical-questionnaire-config>
      </vs-tab>
      <vs-tab :label="$t('participantes')" v-if="showParticipantsTab">
        <physical-questionnaire-participants :id="id"></physical-questionnaire-participants>
      </vs-tab>
      <vs-tab :label="$t('envios')" v-if="showLogsTab">
        <physical-questionnaire-logs :id="id"></physical-questionnaire-logs>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>

<script>
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'
import PhysicalQuestionnaireConfig from './PhysicalQuestionnaireConfig.vue'
import PhysicalQuestionnaireParticipants from './PhysicalQuestionnaireParticipants.vue'
import PhysicalQuestionnaireLogs from './PhysicalQuestionnaireLogs.vue'

export default {

  components: {
    PhysicalQuestionnaireConfig,
    PhysicalQuestionnaireParticipants,
    PhysicalQuestionnaireLogs
  },

  props: {
    id: {
      type: Number,
      default: null
    },
    inlineForm: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    id: null,
    title: '',
    description: '',
    courseName: '',
    courseId: null,
    service: null,
    courseService: null,
    showCourseGrid: false,
    key: 0,
    activeTab: 1
  }),
  watch: {
  },
  computed: {
    validateForm() {
      return !this.isEmpty(this.title)
    },
    showParticipantsTab() {
      return !this.isEmpty(this.id) && this.$acl.hasPermission('physical_questionnaires.participants_tab')
    },
    showLogsTab() {
      return !this.isEmpty(this.id) && this.$acl.hasPermission('physical_questionnaires.logs_tab')
    }
  },
  methods: {
    create() {
      this.clearErrors(this.$validator)
      this.$vs.loading()

      this.service
        .createOrUpdate({
          id: this.id,
        })
        .then(
          data => {
            this.$vs.loading.close()
            this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))
            this.$router.push(`/physical_questionnaires/${data.id}/edit`)
          },
          error => {
            this.$vs.loading.close()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    cancel() {
      this.$router.push('/physical_questionnaires')
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        data => {
          this.$set(this.$data, 'id', data.id)
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    handleCourseSelection(course) {
      if (course) {
        this.courseName = course.name
        this.courseId = course.id
        this.showCourseGrid = false
      }
    },
    requiredClass(whichData) {
      return !this.isEmpty(whichData) ? 'w-full' : 'w-full required'
    }
  },
  beforeMount() {
    this.service = PhysicalQuestionnaireService.build(this.$vs)
    this.activeTab = _.get(this, '$router.currentRoute.query.tab', 0)
  },
  beforeDestroy() {
    this.destroyChilds(this)
  },
}
</script>

<style lang="scss">
  ._physical-questionnaire-form .__required-field {
    border-style: solid;
    border-color: $warning;
    border-width: 0 0 0 0.25rem;
    border-radius: 0.5rem;
  }
</style>
