<template>
  <div>
    <div class="vx-row mt-2">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <select-suggestion
          :label="$t('Questionnaire')"
          :select-class="[{'__required-field': !valid.questionnaire}]"
          column="name,description"
          model="TeacherContentQuestionnaire"
          v-model="questionnaire"
          :appendClearOption="true"
          ref="select_questionnaire"
          placeholderText="Digite o nome do questionário"
        />
        <span
          class="text-danger text-sm"
          v-show="errors.has('questionnaire_id')"
          >{{ errors.first('questionnaire_id') }}</span
        >
      </div>
    </div>

    <div class="vx-row mt-2" v-if="id !== null">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="text-sm p-1" id="description">{{ $t('instrucoes') }}</label>
        <ComplexEditor
          :class="[{'__required-field': !valid.description}]"
          :questionnaire_id.sync="id"
          v-bind:editor_data.sync="description"
          :placeholder="$t('digite-aqui')"
        ></ComplexEditor>
        <span
          class="text-danger text-sm"
          v-show="errors.has('description')"
          >{{ errors.first('description') }}</span
        >
      </div>
    </div>

    <div class="vx-row mt-2" v-if="id !== null">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="text-sm p-1" id="header">{{ $t('cabecalho') }}</label>
        <ComplexEditor
          :class="[{'__required-field': !valid.header}]"
          :model-id.sync="id"
          v-bind:editor_data.sync="header"
          :placeholder="$t('digite-aqui')"
          image-type="base64"
        ></ComplexEditor>
        <span
          class="text-danger text-sm"
          v-show="errors.has('header')"
          >{{ errors.first('header') }}</span
        >
      </div>
    </div>

    <div class="vx-row mt-2" v-if="id !== null">
      <div class="vx-col sm:w-1/1 w-full mb-2">
        <label class="text-sm p-1" id="footer">{{ $t('rodape') }}</label>
        <ComplexEditor
          :class="[{'__required-field': !valid.footer}]"
          :questionnaire_id.sync="id"
          v-bind:editor_data.sync="footer"
          :placeholder="$t('digite-aqui')"
        ></ComplexEditor>
        <span
          class="text-danger text-sm"
          v-show="errors.has('footer')"
          >{{ errors.first('footer') }}</span
        >
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-button
          class="float-right"
          color="primary"
          type="border"
          @click="cancel"
          >{{ $t('action.cancel') }}</vs-button
        >
        <vs-button
          :disabled="!valid.all"
          v-permission="'physical_questionnaires.create'"
          class="float-right mr-2"
          @click="() => store(readModel())"
          >{{ $t('action.save') }}</vs-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'
import ComplexEditor from '@/views/apps/questionnaires/elements/ComplexEditor'
import SelectSuggestion from '@/components/SelectSuggestion.vue'

export default {

  components: {
    ComplexEditor,
    SelectSuggestion
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    service: null,
    description: null,
    header: null,
    footer: null,
    questionnaire:  null
  }),
  watch: {
  },
  computed: {
    valid() {
      const validComplexEditorData = (data) => data !== null && data.length > 0

      const questionnaire = (
        this.questionnaire !== null &&
        _.has(this.questionnaire, 'id')
      )

      const header = validComplexEditorData(this.header)
      const footer = validComplexEditorData(this.footer)
      const description = validComplexEditorData(this.description)

      return {
        all: header && footer && description && questionnaire, header, footer, description, questionnaire
      }
    }
  },
  methods: {
    loadModel(payload) {
      ({
        questionnaire: this.questionnaire,
        header: this.header,
        footer: this.footer,
        description: this.description
      } = payload)
    },
    readModel() {
      const {
        id,
        description,
        header,
        footer
      } = this

      const data = {
        id, description, header, footer
      }

      if (_.has(this, 'questionnaire.id')) {
        data.questionnaire_id = _.get(this, 'questionnaire.id')
      }

      return data
    },
    store(modelData) {
      this.$vs.loading()
      this.service.createOrUpdate(modelData).then(
        (response) => {
          this.clearErrors(this.$validator)
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('operacao-realizada-com-sucesso'))

          if (!this.id && _.has(response, 'id')) {
            this.$router.push(`/physical_questionnaires/${response.id}/edit`)
          }
        },
        (error) => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      )
    },
    getData(id) {
      this.$vs.loading()
      this.service.read(id).then(
        (response) => {
          this.$vs.loading.close()

          this.loadModel(response)
        },
        (error) => {
          this.$vs.loading.close()
          this.showErrors(this.$validator, error)
          this.notifyError(
            this.$vs,
            this.$t('nao-foi-possivel-realizar-esta-operacao')
          )
        }
      )
    }
  },
  created() {
    this.service = PhysicalQuestionnaireService.build(this.$vs)
    if (this.id) {
      this.getData(this.id)
    }
  },
  beforeMount() {

  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>


