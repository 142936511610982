<template>
  <div>
  {{ userName }}

    <vx-card
      :title="$t('correcao-cartao-resposta')"
      class="mb-4"
      collapse-action
    >
      <div class="grid grid-cols-1 gap-4 pt-4">
        <content-file-upload @input="fileUploaded" :uploadUrl="sheetUploadUrl" />
      </div>
    </vx-card>

    <div class="grid grid-cols-3 gap-4 pt-4">
      <div>{{ $t('name') }}</div>
      <div>{{ $t('email') }}</div>
      <div>{{ $t('identificador-matricula') }}</div>
    </div>

    <div class="grid grid-cols-3 gap-4 pt-4">
      <div>
        <select-suggestion
          :class="['flex-grow', {'__required-field': !valid.name}]"
          column="name,email"
          model="User"
          color="dark"
          v-model="user"
          :appendClearOption="true"
          @input="updateNameInput"
          prevent-clear
          ref="select-user"
          placeholderText="Digite o nome ou email do usuário"
          :itemTextFormat="
            (model) => model.name
          "
        />
      </div>
      <div>
        <vs-input
          :class="['w-full', {'__required-field': !valid.email}]"
          v-model="email"
          name="email"
        />
      </div>
      <div>
        <vs-input
          :class="['w-full', {'__required-field': !valid.identifier}]"
          v-model="identifier"
          name="identifier"
        />
      </div>
    </div>

    <div class="flex mt-4 mb-4 justify-end">
      <vs-button
        :disabled="!valid.all"
        type="filled"
        icon="add"
        @click="addNewParticipant()"
        >{{ $t('adicionar-participante') }}</vs-button
      >

      <vs-button
        type="border"
        icon-pack="feather"
        icon="icon-file-plus"
        class="float-right ml-4"
        @click="showParticipantImportPopup = true"
        >{{ $t('importar') }}</vs-button
      >
    </div>

    <vs-divider class="mt-4">{{ $t('participantes-adicionados') }}</vs-divider>

    <div class="grid grid-cols-1 gap-4 pt-4">
      
      <Grid
        ref="grid"
        :service="service"
        :route_grid_path="`${id}/participants`"
        route_name="participants"
        :multipleSelect="true"
        @changedSelection="changedSelection"
        :selectAllCallback="selectAll"
      >
        <template v-slot:actions="actions">
          <feather-icon
            icon="Trash2Icon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
            @click="() => removeParticipant(actions.row)"
          />
          <feather-icon
            icon="EyeIcon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
            v-tooltip.auto="{
              content: 'Abrir detalhamento',
              delay: {
                show: 500,
                hide: 500
              }
            }"
            @click="() => openLogs(actions.row)"
          />
          <feather-icon
            v-if="_.get(actions, 'row.answer_id') !== null"
            icon="Edit3Icon"
            svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
            v-tooltip.auto="{
              content: 'Abrir correção manual',
              delay: {
                show: 500,
                hide: 500
              }
            }"
            @click="() => openManualCorrection(actions.row)"
          />
        </template>
      </Grid>

      <div class="__position-buttons flex w-full gap-2 justify-start">
        <vs-button
          :disabled="selectedParticipants.length === 0"
          @click="downloadQuestionnaireSheets"
          color="primary"
          type="border"
          icon="file_download"
          v-tooltip.auto="{
            content: 'Solicitar ao sistema o caderno de prova dos participantes selecionados;',
            delay: {
              show: 500,
              hide: 500
            }
          }"
        >
          {{ $t('baixar-prova') }}
        </vs-button>
        <vs-button
          :disabled="selectedParticipants.length === 0"
          @click="downloadAnswerSheets"
          color="success"
          type="border"
          icon="file_download"
          v-tooltip.auto="{
            content: 'Solicitar ao sistema a folha de resposta dos participantes selecionados;',
            delay: {
              show: 500,
              hide: 500
            }
          }"
        >
          {{ $t('baixar-cartao-resposta') }}
        </vs-button>
      </div>

    </div>

    <vs-popup
      :title="$t('importar-participantes')"
      :active.sync="showParticipantImportPopup"
      style="overflow-y: auto; z-index: 54000;"
    >
      <div class="flex flex-wrap items-center">
        <ImportExcel 
          :onSuccess="onParticipantSuccess" 
          templateFile="educatena-modelo-importacao-participantes.xlsx"
          :templateMessage="$t('clique-aqui-baixar-modelo-participantes')"
          accept=".xlsx, .xls">
        </ImportExcel>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t('correcao-manual')"
      :active.sync="showManualCorrectionPopup"
      fullscreen
    >
      <physical-questionnaire-answer-correction
        v-if="manualCorrection.participantId"
        :participant-id="manualCorrection.participantId"
      />
    </vs-popup>

    <participant-logs-viewer :service="service"/>
  </div>
</template>

<script>
import ContentFileUpload from '@/components/ContentFileUpload.vue'
import PhysicalQuestionnaireService from '@/services/api/PhysicalQuestionnaireService'
import ImportExcel from '@/components/excel/ImportExcel'
import SelectSuggestion from '@/components/SelectSuggestion.vue'
import InstitutionService from '@/services/api/InstitutionService'
import { getTextReadableTime } from '@/util/Util'
import PhysicalQuestionnaireAnswerCorrection from './PhysicalQuestionnaireAnswerCorrection.vue'
import ParticipantLogsViewer from './ParticipantLogsViewer.vue'


export default {

  components: {
    ContentFileUpload,
    ImportExcel,
    SelectSuggestion,
    PhysicalQuestionnaireAnswerCorrection,
    ParticipantLogsViewer
  },

  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    service: null,
    institutionService: null,
    selectedParticipants: [],
    userName: null,
    user: null,
    email: null,
    identifier: null,
    showParticipantImportPopup: false,
    lastRequestMessages: [],
    highlightParticipant: null,
    showManualCorrectionPopup: false,
    manualCorrection: {
      participantId: null
    },
  }),
  watch: {
    showManualCorrectionPopup(val) {
      if (!val) this.manualCorrection.participantId = null
    },
    user(val) {
      this.userName = _.get(val, 'name')
      this.email = _.get(val, 'email')
      const userId = _.get(val, 'id')

      if (userId) {
        this.institutionService.getUserStudentIdentifier(userId).then(
          (response) => {
            this.identifier = !_.isEmpty(response)? response : null
          }
        )
      }
    }
  },
  computed: {
    grid() {
      return this.$refs['grid']
    },
    valid() {
      const email = _.isString(this.email) && this.email.length > 1
      const name = _.isString(this.userName) && this.userName.length > 1
      const identifier = _.isString(this.email) && this.email.length > 1

      return {
        email, name, identifier,
        all: email && name && identifier
      }
    },
    sheetUploadUrl() {
      return `${process.env.VUE_APP_API_BASE_URL}/api/v1/physical_questionnaires/${this.id}/sheets`
    }
  },
  methods: {
    openLogs(participant) {
      this.$emit('open-logs', participant)
    },
    downloadQuestionnaireSheets() {
      this.$vs.loading()
      this.service.downloadSheets(this.id, this.selectedParticipants).then(
        response => {
          this.$vs.loading.close()
          this.$vs.dialog({
            color: 'success',
            title: this.$t('sucesso'),
            acceptText: 'OK',
            text: this.$t('o-download-esta-sendo-gerado-vamos-lhe-avisar-quando-estiver-pronto'),
          })

        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    downloadAnswerSheets() {
      this.$vs.loading()
      this.service.downloadAnswerSheets(this.id, this.selectedParticipants).then(
        response => {
          this.$vs.loading.close()
          this.$vs.dialog({
            color: 'success',
            title: this.$t('sucesso-0'),
            acceptText: 'OK',
            text: this.$t('o-download-esta-sendo-gerado-vamos-lhe-avisar-quando-estiver-pronto-0'),
          })
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
    changedSelection(items) {
      this.selectedParticipants = items
    },
    selectAll() {
      if (this.service) {
        this.$vs.loading()
        return this.service.selectAllParticipants(this.id).then(
          (response) => {
            this.$vs.loading.close()
            
            return this._.isArray(response) ? response : []          
          },
          (error) => {
            this.$vs.loading.close()
            return []
          }
        )
      }
    },
    openManualCorrection(participant) {
      this.showManualCorrectionPopup = true
      this.manualCorrection.participantId = _.get(participant, 'id')
    },
    formatRowCss(row, column) {
      return ''
    },
    removeParticipant(participant) {
      const id = _.get(participant, 'id', false)
      if (id !== false) {
        this.$vs.loading()
        this.service.removeParticipant(this.id, id).finally(() => {
          this.reloadGrid()
          this.$vs.loading.close()
        })
      }
    },
    clearImportFields() {
      this.userName = null
      this.user = null 
      this.email = null
      this.identifier = null
    },
    updateNameInput(event) {
      this.userName = _.get(event, 'value') || _.get(event, 'model.name')
    },
    addNewParticipant() {
      const participant = {
        email: this.email,
        name: this.userName && _.get(this.user, 'name'),
        identifier: this.identifier
      }

      this.$vs.loading()

      this.service.addParticipants(this.id, {
        imports: [participant]
      }).then(this.processImportResponse)
        .finally(this.finishImport)
    },

    onParticipantSuccess(data) {

      if (data && data.results && data.results.length > 0) {
        this.$vs.loading()
        this.service.addParticipants(this.id, {
          imports: data.results
        }).then(this.processImportResponse)
          .finally(this.finishImport)
      } else {
        this.notifyWarning(this.$vs, this.$t('nenhum-participante-encontrado-para-importar'))
      }

      this.showParticipantImportPopup = false
    },

    fileUploaded() {
      this.notifySuccess(this.$vs, this.$t('iniciando-processamento-do-arquivo'))
    },

    processImportResponse(response) {
      const messages = _.get(response, 'messages', []) || []
      if (messages.length > 0) {
        messages.forEach((message) => {
          this.notifyWarning(this.$vs, message, getTextReadableTime(message))
        })
      }
      const participants = _.get(response, 'participants', []) || []
      const message = this.$t('participants-length-importados-com-sucesso', [participants.length])
      this.notifySuccess(this.$vs, message, Math.floor(getTextReadableTime(message) * 1.5))
    },

    finishImport(response) {
      this.$vs.loading.close()
      this.clearImportFields()
      this.reloadGrid()
    },

    reloadGrid() {
      this.grid.fetchGridData()
    }
  },
  beforeMount() {
    this.service = PhysicalQuestionnaireService.build(this.$vs)
    this.institutionService = InstitutionService.build(this.$vs)

    this.highlightParticipant = _.get(this, '$router.currentRoute.query.p_id', null)
  },
  beforeDestroy() {
    this.destroyChilds(this)
  }
}
</script>

<style lang="scss">
.__position-buttons {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
}
</style>
