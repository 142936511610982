<template>
  <div>
    <vs-popup
      :title="$t('envio')"
      :active.sync="showLog"
      style="z-index: 54000;"
    >
      <div class="flex flex-col gap-4" v-if="log">
        <log-messages v-if="log" :messages="messages"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import LogMessages from '@/components/LogMessages.vue'
export default {
  components: { LogMessages },

  props: {
    service: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    showLog: false,
    log: null,
    messages: null,
    logIndex: null,
  }),

  watch: {
    showLog(value) {
      if (!value) this.closeLog()
    }
  },

  mounted() {
    this.$parent.$on('open-logs', this.loadLog)
  },

  beforeDestroy() {
    this.$parent.$off('open-logs', this.loadLog)
  },

  methods: {
    closeLog() {
      this.log = null
      this.messages = null
      this.$emit('closed-logs')
    },
    loadLog(log) {
      const id = _.get(log, 'id')
      this.log = log
      this.$vs.loading()
      this.service.getLogMessages(id).then(
        response => {
          if (this._.isArray(response)) {
            this.messages = response
          } else {
            this.notifyError(this.$vs, this.$t('formato-invalido-para-mensagens'))
          }
        },
        error => {
          this.notifyError(this.$vs, this.$t('nao-foi-possivel-carregar-as-mensagens-deste-envio'))
        }
      ).finally(() => {
        this.$vs.loading.close()
        this.showLog = true
      })
    }
  },

  computed: {
  },


}
</script>

<style>

</style>